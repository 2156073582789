<template>
    <div class="dashboard-filter panel">
      <div v-if="showFieldEmptyMessage" class="my-1 mx-1 text-bold">
            <h4 class="text-danger">{{ fieldEmptyMessage }}</h4>
        </div>
            <b-row>
                <b-col md="4 mt-1" v-if="nationalUser">
                    <Grupo
                        ref="grupo" 
                        @selected="selectedGroupo"
                        :value="filtros.grupo"
                        :departamento="filtros.departamento"
                        :required="false"
                        :showErrorProp="false"
                    />
                </b-col>
                <b-col md="4 mt-1">
                    <Estado 
                        ref="estado"
                        @selected="selectedEstado"
                        :value="filtros.estado"
                        :departamento="filtros.departamento"
                    />
                </b-col>
                <b-col md="4 mt-1">
                    <UnidadeOperacional 
                        ref="unidade"
                        @selected="selectedUnidade"
                        :value="filtros.unidade"
                        :departamento="filtros.departamento"
                    />
                </b-col>
                <b-col md="5 mt-1">
                    <Empresa 
                        v-if="!filtros.grupo"
                        ref="empresa"
                        @selected="selectedEmpresa"
                        :value="filtros"
                        :grupo="filtros.grupo"
                        :departamento="filtros.departamento"
                        :required="true"
                        :showErrorProp="showErrorCompany"
                    />
                    <EmpresaMultipla 
                    v-else
                    ref="empresa"
                    @selected="selectedEmpresa"
                    :value="filtros.empresa"
                    :grupo="filtros.grupo"
                    :departamento="filtros.departamento"
                    :required="false"
                    :showErrorProp="showErrorCompany"
                     />
                </b-col>
                <b-col md="5 mt-1">
                    <Campanha 
                        ref="campanha"
                        :disable="!filtros.empresa && !filtros.grupo"
                        @selected="selectedCampanha"
                        :value="filtros.campanha"
                        :departamento="filtros.departamento"
                        :empresa="filtros.empresa"
                        :grupo="filtros.grupo"
                        :showErrorProp="showErrorCampaign"
                        :required="true"
                    />
                </b-col>
            </b-row>
        <b-row>
            <b-col class="d-flex justify-content-center">
                <b-button variant="custom-blue" class="btn-lg" @click="sendFielters()">
                    <span class="align-middle">Filtrar</span>
                </b-button>
                <b-button class="ml-2 cor_botao min-width-button-reset btn-lg" variant="outline-primary" @click="resetFilters()">
                    <span class="align-middle">Limpar</span>
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, } from 'bootstrap-vue'
import Departamento from '@/views/custom-pages/company-panel/components/Departamento.vue'
import UnidadeOperacional from '@/views/custom-pages/company-panel/components/UnidadeOperacional.vue'
import Grupo from '@/views/custom-pages/company-panel/components/Grupo.vue'
import Estado from '@/views/custom-pages/company-panel/components/Estado.vue'
import Empresa from '@/views/custom-pages/company-panel/components/Empresa.vue'
import Campanha from '@/views/custom-pages/company-panel/components/Campanha.vue'
import DateFilter from '@/views/custom-pages/company-panel/components/DateFilter.vue'
import {getUserData} from "@/auth/utils";
import { actions, subjects } from '@/libs/acl/rules'
import EmpresaMultipla from '../EmpresaMultipla.vue'
import {getDepartmentTemporaryId, getDepartmentTemporaryUf} from "@core/utils/store/getStore";

export default {
    components: {
        Departamento,
        EmpresaMultipla,
        UnidadeOperacional,
        Grupo,
        Estado,
        Empresa,
        Campanha,
        DateFilter,
        BForm,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BButton
    },
    props: {
        messageFilters: {
            
        }
    },

  async beforeMount() {
    await this.resolveDepartment();
  },
  data() {
        return {
            filtros: {
                departamento: null,
                unidade: null,
                grupo: null,
                estado: null,
                empresa: null,
                campanha: null
            },
            fieldEmptyMessage: "",
            showErrorCampaign: false,
            showErrorCompany: false,
            showFieldEmptyMessage: false
        }
    },

    methods: {
        resetFilters(groupChange = false) {
            this.filtros.unidade = null;
            this.filtros.estado = null;
            this.filtros.empresa = null;

            this.$refs.unidade.clearSelected();
            this.$refs.estado.clearSelected();
            this.$refs.empresa.clearSelected();
            this.filtros.campanha = null;
            this.$refs.campanha.clearSelected(true);

            if(!groupChange && this.$refs.grupo) {
                this.filtros.grupo = null;
                this.$refs.grupo.clearSelected();
            }

            if(this.nationalUser && !groupChange) {
              this.$refs.grupo.clearSelected();
            }
        },

        selectedUnidade(unidade) {
            this.filtros.unidade = unidade;
        },
        selectedGroupo(grupo) {
            this.filtros.grupo = grupo;
        },
        selectedEstado(estado) {
            this.filtros.estado = estado;
        },
        selectedEmpresa(empresa) {
            this.filtros.empresa = empresa;
        },
        selectedCampanha(campanha) {
            this.filtros.campanha = campanha;
        },
        sendFielters() {
            if(this.verifyIfRequiredFieldsArePresent()) {
                return;
            }

            this.showFieldEmptyMessage = false
            this.$emit('filters', this.filtros);
        },
        verifyIfRequiredFieldsArePresent() {
            this.showErrorCampaign = !this.filtros.campanha;
            this.showErrorCompany = !this.filtros.empresa && (!this.filtros.grupo && this.nationalUser);

            return [
                this.showErrorCampaign,
                this.showErrorCompany
            ].some(Boolean);
        },
        async resolveDepartment() {
          const departamentTemporaryId = getDepartmentTemporaryId();

          if (departamentTemporaryId) {
            await this.$http
                .get(this.$api.departamentoParameter(departamentTemporaryId))
                .then(({data}) => {
                  this.filtros.departamento = [data];
                });
          } else {
            this.filtros.departamento = [getUserData().departamento];
          }

      }
    },
    watch: {
        'filtros.grupo': function() {
            this.resetFilters(true);
        },
        'filtros.empresa': function(newValue) {
            if (newValue) {                
                this.filtros.campanha = null;
                this.$refs.campanha.clearSelected();
                this.showErrorCampaign = false;
            }
        }
    },
    computed: {
        nationalUser() {
            return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
        }
    }
}
</script>

<style>

</style>