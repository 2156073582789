<template>
  <b-form-group label="Campanha" label-for="estado" label-class="font_size_label">
      <v-select
        id="estado"
        :options="filteredOptions"
        v-model="selected"
        placeholder="Selecione uma campanha"
        label="descricao"
        :clearable="false"
        @close="markAsTouched"
        :disabled="disable"
      >
        <span slot="no-options">{{ noSelectionMessage }}</span>
      </v-select>
      <small v-if="showError || showErrorProp" class="text-danger">
          O campo de campanha é obrigatório.
        </small>
  </b-form-group>
</template>


<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import { tipoCampanhaEnum } from "@/enum/tipoCampanha";
import { actions, subjects } from "@/libs/acl/rules";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    departamento: {
      required: false,
    },
    required: {
        type: Boolean,
        default: false
      },
    empresa: {
      required: false
    },
    grupo: {
      required: false
    },
    disable: {
        required: false,
        default: false
      },
      showErrorProp: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
      selected: this.value,
      parametros: {
        params: {
          paginacao: false,
          tipoCampanha: null,
          situacao:['Em andamento', 'Concluída'],
          idsEmpresas: null,
          idsGrupos: null
        },
      },
      userData: getUserData(),
      touched: false,
      shouldReset: false
    };
  },
  methods: {
    async setOptions() {

      await this.$http
        .get(this.$api.campanha(), this.parametros)
        .then(({ data }) => {
          this.options = data.map((campanha) => {
            return {
              id_campanha: campanha.id_campanha,
              descricao: campanha.campanha_descricao,
              id_departamento: campanha.id_departamento,
              nacional: campanha.tipo == 'Nacional'
            };
          });
        });

        this.filteredOptions = [...this.options];
        if(this.departamento){
          this.filterByDepartament(this.departamento);
        }
    },
    markAsTouched() {    
        this.touched = true;
    },
    clearSelected(reset = null) {
      if (reset) {
        this.shouldReset = true;
      }
      this.selected = null;
      this.$emit("selected", null);
    },
    filterByDepartament(valor) {
      if (Array.isArray(valor) && valor.length > 0) {
        valor = valor.filter(option => option.id_departamento !== "all");
        const idsDepartamento = valor.map(item => item.id_departamento);
        this.filteredOptions = this.options.filter(
            option => idsDepartamento.includes(option.id_departamento) || option.id_departamento === 1
        );
        
      }
    }
  },
  
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.$emit("options", valor);
    },
    departamento: function (val) {
      this.filterByDepartament(val)
    },
    empresa: function (val) {
      if(!val || (Array.isArray(val) && val.includes(null))){
        this.filteredOptions = [];
        this.parametros.params.idsEmpresas = null
        return;
      }
      let array = Array.isArray(val) ? val : [val];
      this.parametros.params.idsEmpresas = array.map(item => item.id_empresa);
      this.setOptions();
    },
    grupo: function (val) {
      if(!val || (Array.isArray(val) && val.includes(null))){
        this.filteredOptions = [];
        this.parametros.params.idsGrupos = null
        this.parametros.params.tipoCampanha = null
        return;
      }
      let array = Array.isArray(val) ? val : [val];
      this.parametros.params.tipoCampanha = tipoCampanhaEnum.NACIONAL
      this.parametros.params.idsGrupos = array.map(item => item.id_grupo);
      this.setOptions();
    }
  },
  computed: {
    showError() {
        if(this.required && !this.shouldReset){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
      },
      nationalUser() {
            return this.$can(actions.VISUALIZAR, subjects.ATUACAO_BASE_NACIONAL)
      },
      noSelectionMessage(){
          if(this.nationalUser && this.grupo && !this.empresa){
            return "O grupo selecionado não possui adesão."
          }

          if(this.empresa){
            return "A empresa selecionada não possui adesão."
          }
      }
  }
};
</script>

