<template>
    <b-form-group label="Departamento Regional" label-for="departamento" label-class="font_size_label">
        <v-select
          id="departamento"
          :options="computedOptions"
          v-model="selected"
          placeholder="Selecione um Departamento Regional"
          label="descricao"
          :clearable="false"
          :multiselect="true"
          :multiple="true"
          @close="markAsTouched"
          @input="handleSelectionChange"
          :disabled="disable"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
        <small v-if="showError || showErrorProp" class="text-danger">
          Selecione um departamento ou mais departamentos
        </small>
    </b-form-group>
</template>
  
<script>
  import {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  import { getUserData } from "@/auth/utils";
  
  export default {
    components: {
      BForm,
      BCol,
      BRow,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BSpinner,
      BFormDatepicker,
      BFormTextarea,
      BOverlay,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      value: {
        required: true,
      },
      required: {
        type: Boolean,
        default: false
      },
      showErrorProp: {
        type: Boolean,
        default: false
      },
      optionsConsulted: {
        required: false,
        type: Array,
        default: () => [],
      },
      groupSelected: {
        type: Boolean,
        default: false
      },
      disable: {
        required: false,
        default: false
      }
    },
    data() {
      const defaultItem = { 
        id_departamento: "all",
        descricao: "Todos os Departamentos",
        uf: null
      };

      return {
        defaultItem,
        options: [],
        selected: this.value,
        selectedItemsCopy: [],
        parametros: {
          params: {
            paginacao: false,
          },
        },
        userData: getUserData(),
        touched: false,
        shouldReset: false
      };
    },
    beforeMount() {
      this.setOptions();
    },
    methods: {
      async setOptions() {
        if (this.optionsConsulted.length > 0) {
          this.options = this.optionsConsulted;
          return;
        }
  
        await this.$http
          .get(this.$api.departamento(), this.parametros)
          .then(({ data }) => {
            this.options = data.map((departamento) => {
              return {
                id_departamento: departamento.id_departamento,
                descricao: departamento.descricao,
                uf: departamento.uf
              };
            });
          });
      },
      markAsTouched() {        
        this.touched = true;
      },
      clearSelected(reset = null) {
        if (reset) {
          this.shouldReset = true;
        }
        this.selected = null
      },
      handleSelectionChange(newValue) {
        const addedItem = this.getAddedItem(newValue);

        if(!this.selected.length){
          this.selected = this.groupSelected ? [this.defaultItem] : []
          this.selectedItemsCopy = this.selected;
          return;
        }

        if(addedItem && addedItem.id_departamento == 'all'){
            this.selected = this.groupSelected ? [this.defaultItem] : []
        }else{
            this.selected = newValue.filter(option => option.id_departamento !== "all");        
        }

        this.selectedItemsCopy = this.selected;
      },
      getAddedItem(newSelectedItems) {
        let addItem = newSelectedItems.filter(item => 
          !this.selectedItemsCopy.some(selectedItem => selectedItem.id_departamento === item.id_departamento)
        );
        return addItem[0];
      }
    },
    watch: {
      selected: function (valor) {
        this.$emit("selected", valor);
      },
      groupSelected: function (valor) {
        if(valor){
          this.handleSelectionChange([this.defaultItem]);
        }else{
          this.handleSelectionChange([]);
        }
      }
    },
    computed: {
      computedOptions() {
        let list = this.options;

        if(this.groupSelected){
          list = [this.defaultItem, ...this.options];
        }

        return list;
      },
      showError() {
        if(this.required && !this.shouldReset){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
      },
    },

  };
  </script>
  
  