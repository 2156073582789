<template>
  <div>
      <div>
          <button
              class="dash-btn"
              :class="{ active: dashboardView == 'geral' }"
              @click="changeDashboardView('geral')"
          >Visão
            Geral</button>

          <button
              class="dash-btn"
              :class="{ active: dashboardView == 'comparativo' }"
              @click="changeDashboardView('comparativo')"
          >Comparativo</button>
      </div>
      <div v-show="dashboardView == 'geral'" class="dashboard-general-data">
        <b-row class="general-data row-2">
          <b-col >
              <VaccinationDonutChartPanel :vaccinationData="VaccinationDonutChartPanelData" />
          </b-col>
          <b-col class="align-blocks">
                <VaccinationAbsoluteDataPanel v-for="(panel, i) in vaccinationAbsoluteData" :key="i"
                                              :absoluteData="vaccinationAbsoluteData[i]" />
          </b-col>
        </b-row>
        <b-row class="general-data row-3">
          <b-col>
            <EvolutionOrdersVaccinationPanel :data="evolutionData" />
          </b-col>
        </b-row>
      </div>
      <div v-show="dashboardView == 'comparativo'">
        <b-spinner 
                v-if="!empresasComparativo && sortingComparative" 
                class="data-load-spinner" 
                key="spinner" 
            />
        <CompanyComparativeDataPanel
            v-else
            @sortData="sortData($event)"
            @downloadSheet="downloadSheet($event)"
            :sortingComparative="sortingComparative"
            :empresasComparativo="empresasComparativo"
            :downloadingSheet="downloadingSheet"
            />
      </div>
  </div>
</template>

<script>
import moment from 'moment'
import TotalVaccinatedPanel from "./data-panels/TotalVaccinatedPanel.vue"
import CompanyAdhesionPanel from "./data-panels/CompanyAdhesionPanel.vue"
import DoseAdhesionPanel from './data-panels/DoseAdhesionPanel.vue'
import DoseDistributionPanel from './data-panels/DoseDistributionPanel.vue'
import VaccinationTypePanel from '@/views/custom-pages/company-panel/components/VaccinationTypePanel.vue'
import VaccinationPerCollaboratorPanel from '@/views/custom-pages/company-panel/components/VaccinationPerCollaboratorPanel.vue'
import VaccinationDonutChartPanel
  from "@/views/custom-pages/dashboard/company/company-components/VaccinationDonutChartPanel.vue";
import VaccinationAbsoluteDataPanel
  from "@/views/custom-pages/dashboard/company/company-components/VaccinationAbsoluteDataPanel.vue";
import EvolutionOrdersVaccinationPanel
  from "@/views/custom-pages/dashboard/company/company-components/EvolutionOrdersVaccinationPanel.vue";
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import CompanyComparativeDataPanel
  from "@/views/custom-pages/dashboard/company/company-components/CompanyComparativeDataPanel.vue";

export default {
    components: {
      CompanyComparativeDataPanel,
      EvolutionOrdersVaccinationPanel,
      VaccinationAbsoluteDataPanel,
      VaccinationDonutChartPanel,
        TotalVaccinatedPanel,
        CompanyAdhesionPanel,
        DoseAdhesionPanel,
        DoseDistributionPanel,
        VaccinationTypePanel,
        VaccinationPerCollaboratorPanel,
        BRow,
        BCol,
        BSpinner
    },

    props: {
        dashData: {
            type: Object,
            required: true
        },
        filterParams: {
            type: Object,
            required: true
        },
        sortingComparative: {
            type: Boolean,
            default: false
        },
        downloadingSheet: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            showDoseDistribution: true,
            vaccinationAbsoluteData: [
              { img: 'people-blue-icon', value: 0, label: ['Trabalhadores', 'Vacinados'] },
              { img: 'people-yellow-icon', value: 0, label: ['Dependentes', 'Vacinados'] },
              { img: 'people-green-icon', value: 0, label: ['Terceiros', 'Vacinados'] },
              { img: 'doses-left-icon', value: 0, label: ['Doses', 'Restantes'] },
            ],
            evolutionData: {
              numWeek: [],
              dataWeek: [],
              subWeek: [],
              historicoCompleto: []
            },
            VaccinationDonutChartPanelData: {},
            vaccinationRangeData: {},
            dashboardView: 'geral'
        }
    },
    created() {
      this.dadosDashboard();
    },
    methods: {
        changeDashboardView(view) {
          this.dashboardView = view;
        },
        dadosDashboard(){
             // Indicativos Superiores de Vacinação
             this.vaccinationAbsoluteData[0].value = this.dashData.geral.vacinacao.aplicadas.trabalhadores
             this.vaccinationAbsoluteData[1].value = this.dashData.geral.vacinacao.aplicadas.dependentes
             this.vaccinationAbsoluteData[2].value = this.dashData.geral.vacinacao.aplicadas.terceiros
             this.vaccinationAbsoluteData[3].value = this.dashData.geral.vacinacao.pendentes.colaboradores

             // Andamento da Vacinação (gráfico donut)
             this.VaccinationDonutChartPanelData = {
               dosesSolicitadas: this.dashData.geral.doses.solicitadas,
               dosesAplicadas: this.dashData.geral.doses.aplicadas,
             }

             // Alcance da Vacinação (gráfico semi-circulo)
             this.vaccinationRangeData = {
               totalColaborators: parseInt(this.dashData.geral.colaboradores_cadastrados),
               vaccinatedColaborators: parseInt(this.dashData.geral.doses.aplicadas),
             }

             const dataWeek2 = []
             const numWeek2 = []
             let subWeek2 = []
             let historicoCompleto2 = []

             // Evolução da Vacinação (gráfico de linha)
             for (let i = 0; i < this.dashData.geral.vacinacao.historico.length; i++) {
               dataWeek2[i] = this.dashData.geral.vacinacao.historico[i].quantidade
               numWeek2[i] = `Semana ${i + 1}`
             }
             subWeek2 = this.dashData.geral.vacinacao.historico_anos
             historicoCompleto2 = this.dashData.geral.vacinacao.historico_periodo_inicial.map((value, index) => `${value} até ${this.dashData.geral.vacinacao.historico_periodo_final[index]}`)

             this.evolutionData = {
               historicoCompleto: historicoCompleto2,
               numWeek: numWeek2,
               dataWeek: dataWeek2,
               subWeek: subWeek2,
             }

            this.updatedAt = `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`
            this.loadingButton = false
       },
       sortData(event) {
            this.$emit("sortData", event)
       },
       downloadSheet(event) {
            this.$emit("downloadSheet", event)
       }
    },
    computed: {
      empresasComparativo() {
        return this.dashData.comparativo
      }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";

.align-blocks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: responsive-height(25.17px) responsive-height(25.17px);
  flex: 1;
  height: responsive-height(373px) !important;
}

.dash-btn {
  padding: responsive-width(10px);
  margin-bottom: responsive-height(24px);
  background-color: transparent;
  color: $placeholder-color;
  font-size: responsive-height(14px);
  line-height: responsive-height(18px);
  font-weight: 500;
  border: 0;

  &:first-of-type {
    margin-right: responsive-width(20px);
  }

  &.active {
    color: $custom-blue;
    border-bottom: 1px solid $custom-blue;
  }
}

.row-1 .panel { height: responsive-height(251px); }
.row-2 .panel { height: responsive-height(372px); }

.vaccination, .doses-adherence { flex-basis: responsive-width(243px); }
.company-adherence, .doses-distributed { flex-basis: responsive-width(282px); }

/* DATA */
.vaccination, .doses-adherence, .company-adherence {
    .content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .panel-content {
        margin-top: responsive-height(36px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .panel-icon {
        margin-bottom: responsive-height(5px);
    }

    .panel-label {
        text-align: center;
    }

    .row-3 .panel {
      height: responsive-height(463px);
      padding: responsive-height(60px) responsive-width(72px);
      width: 100%;
    }
}


// MOBILE
@media only screen and (max-width: 780px) {
    .row-1 .panel { 
        min-width: 100%; 
    }

    .row-2 .panel {
      min-width: 100%;
      height: fit-content;
    }

    .row-3 .panel {
      flex-basis: 380px;
      padding: mobile-responsive-width(16px);
    }

    .vaccination, .doses-adherence {
        .panel-content {
            margin-top: 10px;
        }

        .panel-icon {
            position: absolute;
            margin-right: 40%;
        }

        .panel-value, .panel-label {
            margin-left: 40%;
        }
    }

    .company-adherence .panel-content {
        margin-top: 10px;
    }
}
</style>