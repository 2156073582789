<template>
    <div class="filter">
        <label class="filter-label" :for="inputDateType">{{ label }}</label>
        
        <b-input-group>
            <b-form-input
                :id="inputDateType"
                v-mask="'##/##/####'"
                v-model="selectedDate"
                placeholder="00/00/0000"
                autocomplete="off"
            />
            <b-input-group-append>
                <b-form-datepicker
                    v-model="pickerDate"
                    locale="pt-BR"
                    button-only
                    right
                    :hide-header="true"
                    :initial-date="initialDate"
                    label-help="Selecione a data de validade"
                    button-variant="custom-blue"
                    today-button label-today-button="Data Atual"
                    close-button label-close-button="Fechar"
                    min="2022-01-01"
                    :max="today"
                    clearable
                    @input="updateSelectedDate()"
                    :disabled="disable"
                />
            </b-input-group-append>
        </b-input-group>

    </div>
</template>

<script>
import moment from 'moment';
import dashUtilsMixin from "@/views/custom-pages/dashboard/shared-mixins/dashUtilsMixin"
import { BInputGroup, BFormInput, BInputGroupAppend, BFormDatepicker } from 'bootstrap-vue';

export default {
    components: {
        BInputGroup, BFormInput, BInputGroupAppend, BFormDatepicker
    },

    mixins: [ dashUtilsMixin ],

    props: {
        value: {
            type: String,
            required: true
        },
        inputDateType: {
            type: String,
            required: true
        },
        disable: {
            required: false,
            default: false
        }
    },

    data() {
        return {
            pickerDate: null,
            selectedDate: this.value,
        }
    },

    computed: {
        label() {
            if (this.inputDateType == "start-date") return "Data Inicial"
            else return "Data Final"
        },

        initialDate() {
            if (this.selectedDate.length == 10) {
                return this.standardDateFormat(this.selectedDate)
            } else {
                return moment().format('YYYY-MM-DD')
            }
        },

        today() {
            return moment().format('YYYY-MM-DD')
        }
    },

    watch: {
        value() {
            this.selectedDate = this.value
        },

        selectedDate() {
            this.$emit("input", this.selectedDate)
        }
    },

    methods: {
        updateSelectedDate() {
            this.selectedDate = this.displayDateFormat(this.pickerDate)
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_responsivity.scss";
@import "@/views/custom-pages/dashboard/shared-styles/filter-field.scss";

input {
    height: responsive-height(38px);
    border-radius: 8px;

    &:focus {
        border: 1px solid #1f9ede;
        outline: 1px solid #1f9ede;
    }
}

.input-group-append {
    height: responsive-height(38px);
}
</style>