<template>
  <b-form-group
    label="Unidade Operacional"
    label-for="unidadeOperacional"
    label-class="font_size_label unidadeOperacional"
  >
      <v-select
        id="unidadeOperacional"
        :options="filteredOptions"
        v-model="selected"
        :placeholder="placeholder"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        :disabled="loading || disable"
        @close="markAsTouched"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="showError" class="text-danger">
          Selecione uma ou mais unidades
        </small>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";


export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    required: {
        type: Boolean,
        default: false
      },
    departamento: {
      required: false,
    },
    disable: {
        required: false,
        default: false
      }
  },
  data() {
    return {
      options: [],
      selected: this.value,
      filteredOptions: [],
      placeholder: "Carregando...",
      loading: true,
      parametros: {
        params: {
          ativo: true,
          colunaOrdenar: "descricao_unidade",
          ids_departamento: [],
        },
      },
      touched: false
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      this.getUnidadeOperacional();
    },

    async getUnidadeOperacional() {
      await this.$http
        .get(this.$api.unidade(), this.setRequestParams())
        .then(({ data }) => {
          this.options = data.map((unidade) => {
            return {
              id_unidade: unidade.id_unidade,
              descricao: unidade.descricao_unidade,
              id_departamento: unidade.id_departamento
            };
          });
        });

        this.filteredOptions = [...this.options];
        this.loading = false;
    },

    setRequestParams() {
      this.addOrRemoveDepartamentos(this.departamento);

      return this.parametros;
    },

    addOrRemoveDepartamentos(departamentos) {      
      if (Array.isArray(departamentos) && departamentos.length > 0) {
        let dep = departamentos.filter(option => option.id_departamento !== "all");
        this.parametros.params.ids_departamento = [];
        dep.flat().forEach((departamento) => {
          this.parametros.params.ids_departamento.push(departamento.id_departamento);
        });
      }
    },
    markAsTouched() {    
        this.touched = true;
    },
    clearSelected() {
      this.selected = null;      
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.loading = this.options.length == 0;
      this.placeholder = this.loading
        ? "Nenhum registro encontrado"
        : "Selecione uma unidade";
      this.$emit("options", valor);
    },

    departamento: function (valor) {      
      if (Array.isArray(valor) && valor.length > 0) {
          const idsDepartamento = valor.map(item => item.id_departamento);
          const allDep = idsDepartamento.includes('all');

          this.filteredOptions = allDep ? this.options : this.options.filter(option => idsDepartamento.includes(option.id_departamento));
        } else {
          this.filteredOptions = [...this.options];
        }
    }
  },
  computed: {
    showError() {
      if(this.required){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
    },
  },

};
</script>

