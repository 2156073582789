<template>
    <div class="primary-select">
      <CompanySelect 
        ref="selectFilterEmpresa"
        :empresaSelect="filteredOptions"
        v-model="selected"
        :multiple="true"
        :disableAll="disabled || loading"
        @close="markAsTouched"
        :loading="loading"
        :showError="showError || showErrorProp"
        errorMessage="O campo Empresa é obrigatório quando um grupo não foi selecionado."
      />
    </div>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
  BFormRadioGroup,
  BFormRadio
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import CompanySelect from "@/views/components/custom/empresa/CompanySelect.vue";


export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
    CompanySelect
  },
  props: {
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false
    },
    grupo: {
      required: false
    },
    departamento: {
      required: false
    },
    disabled: {
        required: false,
        default: false
    },
    dnUser: {
      type: Boolean,
      required: false,
      default: false
    },
    showErrorProp: {
        type: Boolean,
        default: false
      },
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
      selected: this.value,
      placeholder: "Carregando...",
      loading: false,
      parametros: {
        params: {
          ativo: true,
          ids_grupos: [],
        },
      },
      touched: false
    };
  },
  methods: {
    async setOptions(grupo = null, departamento = null) {
      this.loading = true;
      await this.$http
        .get(this.$api.getEmpresaGrupo(), {
          params: {
            ids_grupos: grupo,
            ids_departamentos: departamento
          }
        })
        .then(({ data }) => {
          this.options = data;
          this.filteredOptions = [...this.options];
          this.loading = false;
        });

    },
    markAsTouched() {        
        this.touched = true;
      },
    clearSelected() {
      this.selected = this.value;
    },
  },
  mounted() {
    if(!this.dnUser) {
      this.setOptions(null, this.departamento.map(item => item.id_departamento));
    }
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      const noData = this.options.length == 0
      this.disabledField = noData
      this.placeholder = noData && !this.loading
        ? "Nenhum registro encontrado"
        : "Selecione uma ou mais empresas";

      this.$emit("options", valor);
    },
    grupo: function (valor) {
      let dep = null

      if(Array.isArray(this.departamento) && this.departamento.length > 0) {
        dep = this.departamento.filter(option => option.id_departamento !== "all");
        dep = this.dnUser ? dep.map(item => item.id_departamento) : null;
      }
      let group = valor ? [valor.id_grupo] : null

      this.setOptions(group, dep);
    },
    departamento: function (valor) {
      if (Array.isArray(valor) && valor.length > 0) {
        let dep = valor.filter(option => option.id_departamento !== "all"); 
        this.setOptions(null, dep.map(item => item.id_departamento));
      }
    }
  },
  computed: {
      showError() {
        if(this.required){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
      },
    },
};
</script>

<style>
  .radio-empresa .custom-radio.b-custom-control-sm .custom-control-label::before, [dir=ltr] .input-group-sm .custom-radio .custom-control-label::before {
      left: 0;
  }
</style>