<template>
    <div>
        <div class="dashboard-loading" v-if="isLoading">
            <b-spinner/>
        </div>

        <DashboardHeader :title="'Painel de Indicadores'" :updatedAt="updatedAt" />

        <DnDashboard v-if="perfilDN" @loadFinished="isLoading = false" @filters="getDataDashboard($event)" />
        <DrDashboard v-if="perfilDR" @loadFinished="isLoading = false" @filters="getDataDashboard($event)" />
        <RegiaoUoUnidadeDashboard v-if="perfilSaude || perfilUOAdminUnidade" @loadFinished="isLoading = false" @filters="getDataDashboard($event)" />


        <transition-group name="fade" mode="out-in">
            <BeforeFirstFiltrage 
                v-if="isFirstSearch" 
                class="mt-4" 
                key="before-first-filtrage" 
            />
            <b-spinner 
                v-if="isLoadingData" 
                class="data-load-spinner" 
                key="spinner" 
            />
            <DashboardData 
                v-if="loadData" 
                class="mt-3" 
                :dashData="dashboardData" 
                :filterParams="filterParams"
                :sortingComparative="sortingComparative"
                @sortData="getDataDashboardComparativo(filterParams, $event)"
                @downloadSheet="downloadSheet($event)"
                :downloadingSheet="downloadingSheet"
                key="dashboard-data" 
            />

        </transition-group>
    </div>
</template>

<script>
import { actions, subjects } from '@/libs/acl/rules'
import { BSpinner } from "bootstrap-vue";
import DashboardHeader from '@/views/custom-pages/dashboard/shared-components/DashboardHeader.vue'
import DnDashboard from '@/views/custom-pages/company-panel/components/sesi-user/DnDashboad.vue'
import DrDashboard from '@/views/custom-pages/company-panel/components/sesi-user/DrDashboard.vue'
import RegiaoUoUnidadeDashboard from '@/views/custom-pages/company-panel/components/sesi-user/RegiaoUoUnidadeDashboard.vue'
import DashboardData from '@/views/custom-pages/company-panel/components/DashboardData.vue'
import moment from 'moment'
import BeforeFirstFiltrage from '@/views/custom-pages/company-panel/components/BeforeFirstFiltrage.vue'
import {getUserData} from "@/auth/utils";
import chavePerfil from '@/enum/chavePerfil.js'
import { mountCustomParamsToRequest, downloadComparativeData } from '@/views/custom-pages/dashboard/company/mixins/companyPanelFunctions.js';


export default {
    components: {
        BSpinner,
        DashboardHeader,
        DnDashboard,
        DrDashboard,
        RegiaoUoUnidadeDashboard,
        DashboardData,
        BeforeFirstFiltrage

    },

    data() {
        return {
            isLoading: false,
            updatedAt: `${moment().format('HH:mm')} do dia ${moment().format('DD/MM/YYYY')}`,
            dashboardData: {},
            loadData: false,
            isLoadingData: false,
            isFirstSearch: true,

            perfilDN: this.ehPerfilDN(),
            perfilDR: this.ehPerfilDR(),
            perfilSaude: this.ehPerfilSaude(),
            perfilUOAdminUnidade: this.ehPerfilUoAdminUnidade(),
            filterParams: {},
            sortingComparative: false,
            downloadingSheet: false
        }
    },

    methods: {
        async getDataDashboard(data, pageSortData = null) {
            this.filterParams = data;
            this.loadData = false
            this.isLoadingData = true
            this.isFirstSearch = false
            this.dashboardData = {}

            let customParams = mountCustomParamsToRequest(data, pageSortData);
           
            await this.$http
                .get(this.$api.painelEmpresaDashboard(), {params: customParams})
                .then(({ data }) => {
                    this.dashboardData =  {
                        ...this.dashboardData,
                        geral: data
                    };
                });

            this.isLoadingData = false
            setTimeout(() => {
                this.loadData = true
            }, 1000)
            
            this.sortingComparative = true
            await this.getDataDashboardComparativo(data, pageSortData);
            this.sortingComparative = false
        },
        async getDataDashboardComparativo(data, pageSortData = null) {
            let customParams = mountCustomParamsToRequest(data, pageSortData);
            this.sortingComparative = true

            await this.$http
                .get(this.$api.painelEmpresaDashboardComparativo(), {params: customParams})
                .then(({ data }) => {
                    this.dashboardData =  {
                        ...this.dashboardData,
                        comparativo: data
                    };
                });

            this.sortingComparative = false
        },
        async downloadSheet(data) {
            this.downloadingSheet = true
            let customParams = mountCustomParamsToRequest(this.filterParams, data);
            customParams.selectedCampaign = data.selectedCampaign
            await downloadComparativeData(customParams);
            this.downloadingSheet = false
        },
        ehPerfilDN() {
            const temAcesso = this.$can(actions.VISUALIZAR, subjects.PAINEL_EMPRESA_VISUALIZAR)
            const chaves = [chavePerfil.ADMIN_DN, chavePerfil.SAUDE_DN, chavePerfil.MERCADO_DN]
            const perfil = getUserData().perfis[0].chave

           return temAcesso && chaves.includes(perfil)
        },
        ehPerfilDR() {
            const temAcesso = this.$can(actions.VISUALIZAR, subjects.PAINEL_EMPRESA_VISUALIZAR)
            const chaves = [chavePerfil.ADMIN_DR, chavePerfil.SAUDE_DR, chavePerfil.MERCADO_DR, chavePerfil.MASTER]
            const perfil = getUserData().perfis[0].chave

           return temAcesso && chaves.includes(perfil)
        },
        ehPerfilSaude() {
            const temAcesso = this.$can(actions.VISUALIZAR, subjects.PAINEL_EMPRESA_VISUALIZAR)
            const chaves = [chavePerfil.SAUDE_REGIAO]
            const perfil = getUserData().perfis[0].chave

           return temAcesso && chaves.includes(perfil)
        },
        ehPerfilUoAdminUnidade() {
            const temAcesso = this.$can(actions.VISUALIZAR, subjects.PAINEL_EMPRESA_VISUALIZAR)
            const chaves = [chavePerfil.SAUDE_UO, chavePerfil.ADMIN_UNIDADE]
            const perfil = getUserData().perfis[0].chave

           return temAcesso && chaves.includes(perfil)
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@/assets/scss/_responsivity.scss";
@import "@/views/custom-pages/dashboard/shared-styles/dashboard-filter.scss";
@import "@/views/custom-pages/dashboard/shared-styles/dashboard-data.scss";

/* GENERAL DASHBOARD STYLES */
.dashboard-loading {
	height: 80vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

    .spinner-border {
        color: $custom-blue;
        width: 4rem;
        height: 4rem;
    }
}

.data-load-spinner.spinner-border {
    display: block;
    margin: auto;
    margin-top: responsive-height(150px);
    color: $custom-blue;
    width: 4rem;
    height: 4rem;
}


// MOBILE
@media only screen and (max-width: 780px) {
    .no-data-found, .before-filtrage {
        margin-bottom: 28px;
    }

    .data-load-spinner.spinner-border { 
        margin-top: mobile-responsive-height(35px); 
    }
}
</style>