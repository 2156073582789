<template>
    <div class="dashboard-filter panel">
        <div v-if="showFieldEmptyMessage" class="my-1 mx-1 text-bold">
            <h4 class="text-danger">{{ fieldEmptyMessage }}</h4>
        </div>
        <b-row class="w-100">
            <b-col md="3 mt-1">
                <Departamento 
                    ref="departamento"
                    @selected="selectedDepartamento"
                    :value="filtros.departamento"
                    :required="true"
                    :groupSelected="filtros.grupo ? true : false"
                    :showErrorProp="showErrorDep"
                />
            </b-col>
            <b-col md="3 mt-1">
                <Grupo
                    :disable="!hasDepartamento"
                    ref="grupo" 
                    @selected="selectedGroupo"
                    :value="filtros.grupo"
                    :departamento="filtros.departamento"
                    :required="false"
                    :showErrorProp="false"
                />
            </b-col>
            <b-col md="3 mt-1">
                <Estado 
                    :disable="!hasDepartamento"
                    ref="estado"
                    @selected="selectedEstado"
                    :value="filtros.estado"
                    :departamento="filtros.departamento"
                />
            </b-col>
            <b-col md="3 mt-1">
                <UnidadeOperacional 
                    :disable="!hasDepartamento"
                    ref="unidade"
                    @selected="selectedUnidade"
                    :value="filtros.unidade"
                    :departamento="filtros.departamento"
                />
            </b-col>
            <b-col md="5 mt-1">
                <Empresa 
                    v-if="!filtros.grupo"
                    :disabled="!hasDepartamento"
                    ref="empresa"
                    @selected="selectedEmpresa"
                    :value="filtros"
                    :grupo="filtros.grupo"
                    :departamento="filtros.departamento"
                    :dnUser="true"
                    :required="true"
                    :showErrorProp="showErrorCompany"
                />
                <EmpresaMultipla 
                    v-else
                    :disabled="!hasDepartamento"
                    ref="empresa"
                    @selected="selectedEmpresa"
                    :value="filtros.empresa"
                    :grupo="filtros.grupo"
                    :departamento="filtros.departamento"
                    :dnUser="true"
                    :required="false"
                    :showErrorProp="showErrorCompany"
                />
            </b-col>
            <b-col md="5 mt-1">
                <Campanha
                    :disable="!hasDepartamento || (!filtros.empresa && !filtros.grupo)"
                    ref="campanha"
                    @selected="selectedCampanha"
                    :value="filtros.campanha"
                    :departamento="filtros.departamento"
                    :empresa="filtros.empresa"
                    :grupo="filtros.grupo"
                    :showErrorProp="showErrorCampaign"
                    :required="true"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex justify-content-center">
                <b-button variant="custom-blue" class="btn-lg" @click="sendFielters()">
                    <span class="align-middle">Filtrar</span>
                </b-button>
                <b-button class="ml-2 cor_botao min-width-button-reset btn-lg" variant="outline-primary" @click="resetFilters()">
                    <span class="align-middle">Limpar</span>
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>

<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, } from 'bootstrap-vue'
import Departamento from '@/views/custom-pages/company-panel/components/Departamento.vue'
import UnidadeOperacional from '@/views/custom-pages/company-panel/components/UnidadeOperacional.vue'
import Grupo from '@/views/custom-pages/company-panel/components/Grupo.vue'
import Estado from '@/views/custom-pages/company-panel/components/Estado.vue'
import Empresa from '@/views/custom-pages/company-panel/components/Empresa.vue'
import Campanha from '@/views/custom-pages/company-panel/components/Campanha.vue'
import DateFilter from '@/views/custom-pages/company-panel/components/DateFilter.vue'
import EmpresaMultipla from '../EmpresaMultipla.vue'


export default {
    components: {
        Departamento,
        EmpresaMultipla,
        UnidadeOperacional,
        Grupo,
        Estado,
        Empresa,
        Campanha,
        DateFilter,
        BForm,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BButton
    },
    props: {
        messageFilters: {
            
        }
    },

    data() {
        return {
            filtros: {
                departamento: null,
                unidade: null,
                grupo: null,
                estado: null,
                empresa: null,
                campanha: null
            },
            fieldEmptyMessage: "",
            showFieldEmptyMessage: false,
            showErrorDep: false,
            showErrorCampaign: false,
            showErrorCompany: false,
            hasDepartamento: false
        }
    },

    methods: {
        resetFilters(groupChange = false) {
            if(!groupChange) {
                this.filtros.departamento = null;
                this.$refs.departamento.clearSelected(true);
                this.filtros.grupo = null;
                this.$refs.grupo.clearSelected();
                this.hasDepartamento = false;
            }           
            this.filtros.unidade = null;
            this.filtros.estado = null;
            this.filtros.empresa = null;
            this.showErrorDep = false;
            this.showErrorCampaign = false;
            this.filtros.campanha = null;
            this.$refs.campanha.clearSelected(true);

            this.$refs.unidade.clearSelected();
            this.$refs.estado.clearSelected();
            this.$refs.empresa.clearSelected();
        },

        selectedDepartamento(departamento) {
            this.filtros.departamento = departamento;
            if (!this.filtros.departamento) {
                return this.hasDepartamento = false;
            }

            if (Array.isArray(departamento) && departamento.length == 0) {
                return this.hasDepartamento = false;
            }

            this.hasDepartamento = true;
            
        },
        selectedUnidade(unidade) {
            this.filtros.unidade = unidade;
        },
        selectedGroupo(grupo) {
            this.filtros.grupo = grupo;
        },
        selectedEstado(estado) {
            this.filtros.estado = estado;
        },
        selectedEmpresa(empresa) {
            this.filtros.empresa = empresa;
        },
        selectedCampanha(campanha) {
            this.filtros.campanha = campanha;
        },
        sendFielters() {

            if(this.verifyIfRequiredFieldsArePresent()) {
                return;
            }

            this.showFieldEmptyMessage = false
            this.$emit('filters', this.filtros);
        },
        verifyIfRequiredFieldsArePresent() {
            this.showErrorDep = !this.filtros.departamento ||!this.filtros.departamento.length;
            this.showErrorCampaign = !this.filtros.campanha;
            this.showErrorCompany = !this.filtros.empresa && !this.filtros.grupo;

            return [
                this.showErrorDep,
                this.showErrorCampaign,
                this.showErrorCompany
            ].some(Boolean);
        }
    },
    watch: {
        'filtros.grupo': function() {
            this.resetFilters(true);
        },
        'filtros.empresa': function() {
            if (newValue) {                
                this.filtros.campanha = null;
                this.$refs.campanha.clearSelected();
                this.showErrorCampaign = false;
            }
        }
    }
}
</script>

<style>

</style>