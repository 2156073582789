<template>
    <b-form-group
      label="Grupo de Empresas"
      label-for="grupo"
      label-class="font_size_label"
    >
        <v-select
          id="grupo"
          :options="filteredOptions"
          v-model="selected"
          :placeholder="placeholder"
          label="descricao"
          :clearable="true"
          :disabled="loading || disable"
          @close="markAsTouched"
        >
          <span slot="no-options">Nenhuma opção selecionável.</span>
        </v-select>
        <small v-if="showError || showErrorProp" class="text-danger">
          O campo de Grupo é obrigatório quando a campanha é nacional.
        </small>
    </b-form-group>
</template>
  
<script>
  import {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import vSelect from "vue-select";
  
  import {getUserData} from "@/auth/utils";

  
  export default {
    components: {
      BForm,
      BCol,
      BRow,
      BFormGroup,
      BFormInput,
      BButton,
      BTable,
      BSpinner,
      BFormDatepicker,
      BFormTextarea,
      BOverlay,
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      value: {
        required: true,
      },
      required: {
        type: Boolean,
        default: false
      },
      departamento: {
        required: false
      },
      disable: {
        required: false,
        default: false
      },
      showErrorProp: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        options: [],
        filteredOptions: [],
        userData: getUserData(),
        selected: this.value,
        placeholder: "Carregando...",
        loading: true,
        touched: false
      };
    },
    beforeMount() {
      this.setOptions();
    },
    methods: {
      async setOptions() {  
        await this.$http
          .get(this.$api.getGruposCampanhaNacional())
          .then(({ data }) => {
            this.options = data.map((grupo) => {
  
              return {
                id_grupo: grupo.id_grupo,
                descricao: grupo.descricao,
                operador: grupo.operador
              };
            });
            this.filteredOptions = [...this.options];
          });
      },
      markAsTouched() {        
        this.touched = true;
      },
      clearSelected() {
        this.selected = null
      },
    },
    watch: {
      selected: function (valor) {
        this.$emit("selected", valor);
      },
      options: function (valor) {
        this.loading = this.options.length == 0;
        this.placeholder = this.loading
          ? "Nenhum registro encontrado"
          : "Selecione um grupo de empresas";
  
        this.$emit("options", valor);
      }
    },
    computed: {
      showError() {
        if(this.required){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
      },
    },
  };
  </script>
  
  