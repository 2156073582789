<template>
    <div class="dashboard-filter panel">
        <div v-if="showFieldEmptyMessage" class="my-1 mx-1 text-bold">
            <h4 class="text-danger">{{ fieldEmptyMessage }}</h4>
        </div>
        <b-row class="w-100">
            <b-col md="4 mt-1">
                <Estado 
                    ref="estado"
                    @selected="selectedEstado"
                    :value="filtros.estado"
                    :departamento="filtros.departamento"
                />
            </b-col>
            <b-col md="4 mt-1">
                <UnidadeOperacional 
                    ref="unidade"
                    @selected="selectedUnidade"
                    :value="filtros.unidade"
                    :departamento="filtros.departamento"
                />
            </b-col>
            <b-col md="5 mt-1">
                <Empresa 
                    ref="empresa"
                    @selected="selectedEmpresa"
                    :value="filtros"
                    :grupo="filtros.grupo"
                    :departamento="filtros.departamento"
                    :required="true"
                    :showErrorProp="showErrorCompany"
                />
            </b-col>
            <b-col md="5 mt-1">
                <Campanha 
                    ref="campanha"
                    :disable="!filtros.empresa"
                    @selected="selectedCampanha"
                    :value="filtros.campanha"
                    :departamento="filtros.departamento"
                    :estado="filtros.campanha"
                    :empresa="filtros.empresa"
                    :showErrorProp="showErrorCampaign"
                    :required="true"
                />
            </b-col>                 
        </b-row>

        <b-row>
            <b-col class="d-flex mt-2 justify-content-center">
                <b-button variant="custom-blue" class="btn-lg" @click="sendFielters()">
                    <span class="align-middle">Filtrar</span>
                </b-button>
                <b-button class="ml-2 cor_botao min-width-button-reset btn-lg" variant="outline-primary" @click="resetFilters()">
                    <span class="align-middle">Limpar</span>
                </b-button>
            </b-col>
        </b-row>

    </div>
</template>


<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, } from 'bootstrap-vue'
import Departamento from '@/views/custom-pages/company-panel/components/Departamento.vue'
import UnidadeOperacional from '@/views/custom-pages/company-panel/components/UnidadeOperacional.vue'
import Estado from '@/views/custom-pages/company-panel/components/Estado.vue'
import Empresa from '@/views/custom-pages/company-panel/components/Empresa.vue'
import Campanha from '@/views/custom-pages/company-panel/components/Campanha.vue'
import DateFilter from '@/views/custom-pages/company-panel/components/DateFilter.vue'
import {getUserData} from "@/auth/utils";

export default {
    components: {
        Departamento,
        UnidadeOperacional,
        Estado,
        Empresa,
        Campanha,
        DateFilter,
        BForm,
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BButton
    },
    data() {
        return {
            filtros: {
                departamento: [getUserData().departamento],
                unidade: null,
                estado: null,
                empresa: null,
                campanha: null
            },
            fieldEmptyMessage: "",
            showErrorCampaign: false,
            showErrorCompany: false,
            showFieldEmptyMessage: false
        }
    },

    methods: {
        resetFilters() {
            this.filtros.unidade = null;
            this.filtros.estado = null;
            this.filtros.empresa = null;
            this.filtros.campanha = null;

            this.$refs.unidade.clearSelected();
            this.$refs.estado.clearSelected();
            this.$refs.empresa.clearSelected();
            this.$refs.campanha.clearSelected(true);
        },

        selectedUnidade(unidade) {
            this.filtros.unidade = unidade;
        },
        selectedEstado(estado) {
            this.filtros.estado = estado;
        },
        selectedEmpresa(empresa) {
            this.filtros.empresa = empresa;
        },
        selectedCampanha(campanha) {
            this.filtros.campanha = campanha;
        },
        sendFielters() {
            if(this.verifyIfRequiredFieldsArePresent()) {
                return;
            }

            this.showFieldEmptyMessage = false
            this.$emit('filters', this.filtros);
        },
        verifyIfRequiredFieldsArePresent() {
            this.showErrorCampaign = !this.filtros.campanha;
            this.showErrorCompany = !this.filtros.empresa && this.filtros.campanha && !this.nacional;

            return [
                this.showErrorCampaign,
                this.showErrorCompany
            ].some(Boolean);
        }
    },
}
</script>

<style>

</style>