<template>
  <div>    
    <section>
        <DashboarCompanyPanelView />
    </section>

  </div>
</template>

<script>
import moment from 'moment'
import DashboarCompanyPanelView from '@/views/custom-pages/company-panel/Index.vue'

export default {
  title: 'Painel de Empresa',

  components: {
    DashboarCompanyPanelView,
  },

  data() {
    return {
      moment,
    }
  },
}
</script>

<style scoped>
.box-img-home {
  max-width: 500px;
  position: relative;
}

.box-img-home img {
  width: 100%;
  opacity: .3;
}
</style>
