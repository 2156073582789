<template>
  <b-form-group label="Estado" label-for="estado" label-class="font_size_label">
      <v-select
        id="estado"
        :options="filteredOptions"
        v-model="selected"
        placeholder="Selecione um estado"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        @close="markAsTouched"
        :disabled="disable"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="showError" class="text-danger">
          Selecione um estado ou mais estados
      </small>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    required: {
      type: Boolean,
      default: false
    },
    departamento: {
      required: false,
    },
    disable: {
        required: false,
        default: false
      }
  },
  data() {
    return {
      options: [],
      filteredOptions: [],
      selected: this.value,
      parametros: {
        params: {
          paginacao: false,
        },
      },
      userData: getUserData(),
      touched: false
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    async setOptions() {
      await this.$http
        .get(this.$api.getEstados())
        .then(({ data }) => {
          this.options = data.map((estado) => {
            return {
              uf: estado.uf,
              descricao: estado.nome,
            };
          });
        });

        if (Array.isArray(this.departamento) && this.departamento.length > 0) {
            return this.filterOptionsByDepartamento(this.departamento);
        }

        this.filteredOptions = [...this.options];
    },

    filterOptionsByDepartamento(departamento) {
        if (Array.isArray(departamento) && departamento.length > 0) {
            const ufsDepartamento = departamento.map(item => item.uf);
            const allDep = ufsDepartamento.includes(null);
            this.filteredOptions = allDep ? this.options : this.options.filter(option => 
                ufsDepartamento.includes(option.uf)
            );
        } else {
            this.filteredOptions = [...this.options];
        }
    },
    markAsTouched() {    
        this.touched = true;
    },
    clearSelected() {
      this.selected = null;      
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.$emit("options", valor);
    },
    departamento: function (valor) {      
      this.filterOptionsByDepartamento(valor);
    }
  },
  computed: {
    showError() {
      if(this.required){
          return this.touched && (!this.selected || this.selected.length === 0);
        }
        
        return false
    },
  },
};
</script>

